import { render, staticRenderFns } from "./Tuan.vue?vue&type=template&id=671cec61&scoped=true&"
import script from "./Tuan.vue?vue&type=script&lang=js&"
export * from "./Tuan.vue?vue&type=script&lang=js&"
import style0 from "./Tuan.vue?vue&type=style&index=0&id=671cec61&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671cec61",
  null
  
)

export default component.exports