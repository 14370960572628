<template>
    <div class="share">
        <img :src="data" id="bg">
    </div>
</template>

<script>
  import {getBill2} from "@/api/goods";
  import {userUpPic} from "@/api/user";

  export default {
    name: "Hai",
    data () {
      return {
        data: "",
        qrStyle: {
        }
      }
    },
    methods: {
      async loadGoodsInfo () {
        userUpPic().then(data => {
          if (data.success == true) {
            this.data = data.data;
            this.drawer()
          }
        })
      },
    },
    created () {
      this.loadGoodsInfo()
    }
  }
</script>

<style scoped>
    .share img {
        width: 100%;
    }
</style>
